@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fffdf9;
}

.markdown {
  max-width: 100%;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin-bottom: 0.5em;
}

.markdown h1 {
  font-size: 1.5em;
}

.markdown h2 {
  font-size: 1.3em;
}

.markdown h3 {
  font-size: 1.2em;
}

.markdown h4 {
  font-size: 1.1em;
}

.markdown h5 {
  font-size: 1em;
}

.markdown h6 {
  font-size: 0.9em;
}

.markdown strong {
  font-weight: bold;
}

.markdown em {
  font-style: italic;
}

.markdown p {
  margin-bottom: 0.8em;
}

.markdown ul,
.markdown ol {
  margin-top: 0;
  margin-bottom: 0.8em;
  padding-left: 2em;
}

.markdown ul li {
  margin-bottom: 0.8em;
  list-style-type: disc;
}

.markdown ol li {
  margin-bottom: 0.8em;
  list-style-type: decimal;
}

.markdown a {
  color: inherit;
  text-decoration: underline;
}

.markdown a:hover {
  text-decoration: none;
}

.markdown pre {
  background-color: #f0f0f0;
  padding: 8px;
  overflow-x: auto;
  border-radius: 4px;
  margin-bottom: 0.8em;
}

.markdown code {
  font-family: "Roboto Mono", monospace;
  padding: 2px 4px;
  background-color: #f0f0f0;
  border-radius: 3px;
}

.markdown > *:last-child {
  margin-bottom: 0;
}
